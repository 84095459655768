<template>
  <div>
    <div class="turnier-detail-item card" v-if="turnier.beschreibung">
      <p class="p-unstyled text-description">{{turnier.beschreibung}}</p>
    </div>
    <div class="turnier-detail-item card">
      <div class="detail-part" v-if="turnier.datum">
        <i class="far fa-calendar-check"></i>
        <span class="description">{{lang.turnierForm.labelDatum}}</span>
        <span class="detail">{{dateFormatted(turnier.datum)}}</span>
      </div>
      <div class="detail-part" v-if="turnier.startZeit">
        <i class="far fa-clock"></i>
        <span class="description">{{lang.turnierForm.labelStartzeit}}</span>
        <span class="detail">{{turnier.startZeit.split(":")[0]}}:{{turnier.startZeit.split(":")[1]}}</span>
      </div>
      <div class="detail-part" v-if="turnier.stadt">
        <i class="fas fa-map-marker-alt"></i>
        <span class="description">{{lang.turnierForm.labelStadt}}</span>
        <span class="detail">{{turnier.stadt}}</span>
      </div>
      <div class="detail-part" v-if="turnier.passwort">
        <i class="fas fa-key"></i>
        <span class="description">{{lang.turnierForm.labelPasswort}}</span>
        <span class="detail">{{turnier.passwort}}</span>
      </div>
      <div class="detail-part" v-if="turnierGruender.length != 0">
        <i class="fas fa-user-tie"></i> 
        <span class="description">{{lang.turnierForm.labelGruender}}</span>
        <span class="detail">
          <span v-for="turnierGruender in turnierGruender" :key="turnierGruender.turnierSpielerID.spielerID">
            {{turnierGruender.spieler.vorname}} {{turnierGruender.spieler.nachname}}
          </span>
        </span>
      </div>
    </div>
    <div class="turnier-detail-item card">
      <div class="detail-part" v-if="turnier.spielmodus">
        <i class="fas fa-bezier-curve"></i>
        <span class="description">{{lang.turnierForm.labelSpielmodus}}</span>
      <span class="detail">{{getSpielModusBezeichnung(turnier.spielmodus)}}</span>
      </div>
      <div class="detail-part" v-if="turnier.anzTische">
        <i class="fas fa-table-tennis"></i>
        <span class="description">{{lang.turnierForm.labelTische}}</span>
        <span class="detail">{{turnier.anzTische}}</span>
      </div>
      <div class="detail-part" v-if="turnier.anzTeams">
        <i class="fas fa-user-friends"></i>
        <span class="description">{{lang.turnierForm.labelTeams}}</span>
        <span class="detail">{{turnier.anzTeams}}</span>
      </div>
      <div class="detail-part" v-if="turnier.spielmodus == SPIELMODUS.KO_SYSTEM.name">
        <i class="fas fa-list-ol"></i>
        <span class="description">{{lang.turnierForm.labelSpieleKOTeam}}</span>
        <span class="detail">{{anzSingleSpieleTeam}}</span>
      </div>
      <div class="detail-part" v-if="turnier.spielmodus == SPIELMODUS.KO_SYSTEM.name">
        <i class="fas fa-list-ol"></i>
        <span class="description">{{lang.turnierForm.labelSpieleKOAll}}</span>
        <span class="detail">{{anzSingleSpiele}}</span>
      </div>
      <div class="detail-part" v-if="turnier.anzGruppen && turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name">
        <i class="fas fa-people-arrows"></i>
        <span class="description">{{lang.turnierForm.labelGruppen}}</span>
        <span class="detail">{{turnier.anzGruppen}}</span>
      </div>
      <div class="detail-part" v-if="turnier.gruppenPhaseZweiterWeiter && turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name">
        <i class="fas fa-user-check"></i>
        <span class="description">{{lang.turnierForm.labelGruppenPhaseZweiterWeiterDetail}}</span>
        <span class="detail">{{turnier.gruppenPhaseZweiterWeiter ? lang.detailTurnier.textYes: lang.detailTurnier.textNo}}</span>
      </div>
    </div>
    <div class="turnier-detail-item card">
      <div class="detail-part" v-if="turnier.pausenZeiten">
        <i class="fas fa-stopwatch"></i>
        <span class="description">{{lang.turnierForm.labelGeplantePausenzeiten}}</span>
        <span class="detail">{{turnier.pausenZeiten}} Min.</span>
      </div>
      <div class="spiel-zeiten" v-if="turnier.spielArtTurnier && turnier.spielArtTurnier.length != 0">
        <div v-for="spielArt in turnier.spielArtTurnier" :key="spielArt.spielArtTurnierSchluessel.spielArtID">
          <div class="detail-part" v-if="
              (turnier.spielmodus == SPIELMODUS.KO_SYSTEM.name && spielArt.spielArt.spielArtEnum == SPIELART.EINZELSPIEL.name) ||
              (turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name && anzTeamsFirstFinalGame >= SPIELART[spielArt.spielArt.spielArtEnum].countTeams && spielArt.spielArt.spielArtEnum != SPIELART.EINZELSPIEL.name)">
            <i class="far fa-clock"></i>
            <span class="description">{{lang.turnierForm.labelSpielZeit}} {{SPIELART[spielArt.spielArt.spielArtEnum] ? lang.spielart[spielArt.spielArt.spielArtEnum] : ''}}</span>
            <span class="detail">{{spielArt.spielZeit == 0 ? "∞" : spielArt.spielZeit}} {{lang.turnierForm.labelZeitEinheitShort}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/index'

export default {
  name: 'TurnierDetailComponent',
  props: {
    turnier: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      SPIELART: store.getters.getSpielArt,
      SPIELMODUS: store.getters.getSpielModus,
    }
  },
  methods: {
    dateFormatted(dateString) {
      if (dateString != undefined || dateString != null) {
        let date = dateString.split("-");
        return date[2] + "." + date[1] + "." + date[0];
      } else {
        return "";
      }
    },
    getSpielModusBezeichnung(spielmodus) {
      if (this.SPIELMODUS[spielmodus].name == "GRUPPEN_SYSTEM") {
        return this.lang.spielmodus.GRUPPEN_SYSTEM;
      } else if (this.SPIELMODUS[spielmodus].name == "KO_SYSTEM") {
        return this.lang.spielmodus.KO_SYSTEM;
      } else {
        return '';
      }
    },
    binomial(n, k) {
      if ((typeof n !== 'number') || (typeof k !== 'number')) {
        return false; 
      }
      let coeff = 1;
      for (var x = n-k+1; x <= n; x++) {
        coeff *= x; 
      }
      for (x = 1; x <= k; x++) {
        coeff /= x;
      } 
      return coeff;
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    turnierGruender() {
      if (this.turnier.spielerTurnierRolle === undefined || this.turnier.spielerTurnierRolle.length == 0) {
        return [];
      } else {
        return this.turnier.spielerTurnierRolle.filter(s => s.spielerRolle.rollenBezeichnung === store.state.SPIELER_ROLLEN.GRUENDER);
      }
    },
    anzSingleSpiele() {
      if (this.turnier.anzTeams < 2 || this.turnier.anzTeams == undefined) {
        return 0;
      } else {
        return this.binomial(this.turnier.anzTeams, 2);
      }
    },
    anzSingleSpieleTeam() {
      if (this.turnier.anzTeams < 2 || this.turnier.anzTeams == null) {
        return 0;
      } else {
        return this.turnier.anzTeams-1;
      }
    },
    anzTeamsFirstFinalGame() {
      if (this.turnier.anzGruppen == null || this.turnier.anzGruppen < 2) {
        return 0;
      }
      let anzteamsFinalSpiel1 = this.turnier.anzGruppen * (this.turnier.gruppenPhaseZweiterWeiter ? 2 : 1);
      if (anzteamsFinalSpiel1 < 2) {
        return 0;
      }
      let exponent = 0;
      do {
          if (anzteamsFinalSpiel1 > Math.pow(2, exponent) && anzteamsFinalSpiel1 < Math.pow(2, exponent + 1)) {
              anzteamsFinalSpiel1 = Math.pow(2, exponent + 1);
          }
          exponent += 1;
      } while (anzteamsFinalSpiel1 != Math.pow(2, exponent))

      return anzteamsFinalSpiel1;
    },
  },
}
</script>