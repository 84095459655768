<template>
    <div class="app-container" v-if="isDataLoaded" :class="admin ? 'button-bottom' : ''">
            <div v-if="isTurnierEmpty">
                <md-empty-state class="empty-state-turnier-detail"
                    md-icon="sports_bar"
                    :md-label="lang.detailTurnier.emptyStateLabel"
                    :md-description="lang.detailTurnier.emptyStateDescription">
                    <router-link :to="{name: 'ListTurniere'}">
                        <md-button class="md-primary md-filled md-round md-raised">{{lang.detailTurnier.emptyStateButtonText}}</md-button>
                    </router-link>
                </md-empty-state>
            </div>
            <div v-else>
                <TurnierDetailComponentAdmin :turnierID="turnierID" v-if="admin"/>
                <TurnierDetailComponentUser :turnierID="turnierID" v-else/>
            </div>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import TurnierDetailComponentUser from '@/components/Turnier/TurnierDetailComponentUser.vue'
import TurnierDetailComponentAdmin from '@/components/Turnier/TurnierDetailComponentAdmin.vue'

export default {
    name: 'TurnierDetail',
    props: ['turnierID'],
    components: {
        TurnierDetailComponentUser,
        TurnierDetailComponentAdmin
    },
    created() {
        store.commit("SET_DATA_LOADED_STATUS_TURNIER", false);
        store.dispatch('getSingleTurnier', this.turnierID);

        if (Object.keys(store.getters.getSpielerData) == 0) {
            store.dispatch('getSpielerData');
        }
        store.dispatch('getTeamsByTurnierID', this.turnierID);
    },
    mounted() {
        if (this.hasSpielerTurnierGastRolle) {
            store.dispatch('getSpieleByTurnierID', this.turnierID);
            store.dispatch('getGruppenByTurnierID', this.turnierID);
            store.dispatch('getTeilnahmenByTurnierID', this.turnierID);
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.turnier.loadingStatus || store.state.team.loadingStatus || store.state.spieler.loadingStatus;
        },
        admin() {
            return store.getters.isSpielerTurnierAdmin(this.turnierID);
        },
        hasSpielerTurnierGastRolle() {
            return store.getters.hasSpielerTurnierGastRolle(this.turnierID);
        },
        isTurnierEmpty() {
            return Object.keys(store.getters.getTurnier).length == 0;
        },
        isDataLoaded() {
            return store.state.turnier.isDataLoaded;
        },
    },
}
</script>
