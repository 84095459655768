<template>
  <div class="turnier-detail">
    <header>
        <router-link :to="{name: 'ListTurniere'}">
          <button class="btn-unstyled btn-back">
            <i class="fas fa-chevron-left"></i>
          </button>
        </router-link>
        <h2>{{turnier.turnierName}}</h2>
    </header>
    <div class="image-container"></div>
    <div class="container">
      <transition appear name="fadeRight" mode="out-in">
        <div class="turnier-details-container">
          <TurnierDetailComponent :turnier="turnier"/>
          <div v-if="teamIDByPlayer == 0 && !isTurnierBeendet" class="btn-center-container">
              <md-button @click="turnierTeilnehmen()" class="md-primary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextBeitreten}}</md-button>
          </div>
          <div v-if="teamIDByPlayer != 0">
            <div class="button-list">
              <router-link :to="{ name: turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name ? 'ListGruppenSpiele' : 'ListSingleSpiele', params: { turnierID: turnierID }}">
                  <md-button class="md-primary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextSpiele}}</md-button>
              </router-link>
              <router-link :to="{ name: 'ListTeams', params: { turnierID: turnierID }}">
                  <md-button class="md-primary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextTeams}}</md-button>
              </router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <md-dialog-prompt
      :md-active.sync="activePromptDialogPasswort"
      v-model="turnierPasswort"
      :md-title="lang.detailTurnier.dialogHeadlinePasswort"
      :md-input-placeholder="lang.detailTurnier.dialogPlaceholderPasswort"
      :md-cancel-text="lang.detailTurnier.buttonDialogCancel"
      :md-confirm-text="lang.detailTurnier.buttonDialogAgree"
      @md-confirm="setTurnierRolleGast()"/>
    <SubmitModal/>
    <BottomBarDefault/>
  </div>
</template>

<script>
import store from '@/store/index'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'
import TurnierDetailComponent from './TurnierDetailComponent'

export default {
  name: 'TurnierDetailComponentUser',
  props:['turnierID'],
  components: {
    BottomBarDefault,
    SubmitModal,
    TurnierDetailComponent
  },
  data() {
    return {
      SPIELMODUS: store.getters.getSpielModus,
      activePromptDialogPasswort: false,
      turnierPasswort: "",
    }
  },
  methods: {
    turnierTeilnehmen() {
      if (this.hasSpielerTurnierGastRolle) {
        router.push({name: 'ListTeams', params:{turnierID: this.turnierID}});
      } else {
        this.activePromptDialogPasswort = true;
      }
    },
    async setTurnierRolleGast() {
      const data = {
        turnierID: this.turnierID,
        turnierPasswort: this.turnierPasswort
      }
      await store.dispatch('postTurnierRolleGast', data)
        .then(result => {
            if (result.data != null) {
              store.commit("ADD_SPIELER_TURNIER_ROLLE", result.data);
              router.push({name: 'ListTeams', params:{turnierID: this.turnierID}});
            } else {
              this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
            }
        })
        .catch(error => {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spieler.apiReponseErrorMessage)
            setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
        });
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    spielerID() {
      return store.getters.getSpielerID;
    },
    teamIDByPlayer() {
      if (this.hasSpielerTurnierGastRolle) {
        return store.getters.getPlayerTeamNumber(this.spielerID);
      } else {
        return 0;
      }
    },
    turnier() {
      return store.getters.getTurnier;
    },
    hasSpielerTurnierGastRolle() {
      return store.getters.hasSpielerTurnierGastRolle(this.turnierID);
    },
    isTurnierBeendet() {
      return store.getters.isTurnierBeendet;
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';

.button-list {
    margin: 25px auto 20px auto;
}
</style>
