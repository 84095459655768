<template>
  <div class="submit-modal">
      <md-dialog :md-active.sync="submitModal.show" :md-fullscreen="true">
        <transition appear enter-active-class="zoomInFade">
          <form @submit.prevent="sendTeamsWeiter">
            <div class="dialog-content-submit-modal">
              <p class="headline">{{submitModal.headline}}</p>
              <p class="message">{{submitModal.message}}</p>
              <md-button @click="routeToGruppen()" class="md-primary button-center">{{lang.detailTurnier.modalFinalPhaseLinkText}}</md-button>
              <div v-for="(teamData, indexArray) in submitModal.data" :key="indexArray" class="team-container">
                <ul class="ul-unstyled">
                  <li v-for="(team, index) in teamData.teams" :key="index">
                    <p class="team-headline" v-if="index == 0">{{team.platzierung + lang.detailTurnier.modalFinalPhaseTeamHeadline + getGruppe(team.gruppeTeamID.gruppenID)}}</p>
                    <p v-if="index == 0 && teamData.countTeamsWeiter> 1">{{lang.detailTurnier.modalFinalPhaseTeamChoose1}} {{teamData.countTeamsWeiter}} {{lang.detailTurnier.modalFinalPhaseTeamChoose2}}</p>
                    <md-button :class="teamIDsWeiter.includes(team.team.teamID) ? 'md-primary':'' " @click="selectTeam(team.team.teamID, indexArray)" class="md-raised">{{team.team.teamName}}</md-button>
                  </li>
                </ul>
              </div>
              <md-dialog-actions>
                <md-button @click="closeDialog()">{{lang.modal.buttonTextCancel}}</md-button>
                <md-button type="submit" class="md-primary">{{lang.modal.buttonTextAgree}}</md-button>
              </md-dialog-actions>
            </div>
          </form>
        </transition>
      </md-dialog>
  </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import SubmitModal from '../Utils/SubmitModal'

export default {
  name: 'SubmitModalFinalPhase',
  props: {
    turnierID: String
  },
  components: {
    SubmitModal
  },
  data() {
    return {
      teamIDsWeiter: [],
    }
  },
  created() {
    store.dispatch('getGruppenByTurnierID', this.turnierID);
  },
  methods: {
    closeDialog() {
      store.commit("SET_SHOW_SUBMIT_MODAL_FINAL_PHASE_STATUS", false);
    },
    routeToGruppen(){
      this.closeDialog();
      router.push({name: 'ListGruppen', params:{ turnierID: this.turnierID }}); 
    },
    getGruppe(gruppenID) {
      const gruppe = store.getters.getGruppeByGruppenID(gruppenID);
      return gruppe == undefined ? "" : gruppe.gruppenName;
    },
    selectTeam(teamIDSelected, index) {
      const teamIDs = this.submitModal.data[index].teams.map(t => t.team.teamID);
      let teamIDsWeiterIndex = this.teamIDsWeiter.filter(teamID => teamIDs.includes(teamID));
      const countTeamsWeiterIndex = this.submitModal.data[index].countTeamsWeiter;
      const teamIDSelectedIndex = teamIDsWeiterIndex.indexOf(teamIDSelected);

      if (teamIDSelectedIndex > -1) {
        teamIDsWeiterIndex.splice(teamIDSelectedIndex, 1);
      } else {
        teamIDsWeiterIndex.push(teamIDSelected);
      }

      if (teamIDsWeiterIndex.length > countTeamsWeiterIndex) {
        teamIDsWeiterIndex.shift();
      }
      this.teamIDsWeiter = this.teamIDsWeiter.filter(teamID => !teamIDs.includes(teamID));
      this.teamIDsWeiter = [...this.teamIDsWeiter, ...teamIDsWeiterIndex]
    },
    sendTeamsWeiter() {
      const countSelectedTeamsNeeded = this.submitModal.data.reduce((sum, count) => sum + count.countTeamsWeiter, 0);
      if (countSelectedTeamsNeeded != this.teamIDsWeiter.length) {
        this.showDialogActive(false, this.lang.modal.errorHeadline, ""+this.lang.detailTurnier.modalFinalPhaseHinweis);
      } else {
        this.startFinalPhase();
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
    async startFinalPhase() {
      await store.dispatch('startFinalPhase', {turnierID: this.turnierID, teamsWeiterSamePlatzierung: this.teamIDsWeiter})
        .then(result => {
          if (result.data != null) {
            store.commit("SET_SHOW_SUBMIT_MODAL_FINAL_PHASE_STATUS", false);
            this.showDialogActive(true, this.lang.detailTurnier.successHeadlineStartFinalPhase, "");
            setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
          } else {
            this.teamIDsWeiter = [];
            store.commit("SET_SHOW_SUBMIT_MODAL_FINAL_PHASE_DATA", store.state.turnier.apiReponseErrorData);
          }
        })
        .catch(error => {
          this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
        });
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    submitModal: {
      get: function () {
        return store.getters.getSubmitModalFinalPhase;
      },
      set: function (newValue) {}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/base';

.md-dialog .md-dialog-container {
  max-width: 768px;
}

.dialog-content-submit-modal {
  padding: 30px 20px;
}

.dialog-icon {
  margin-bottom: 20px;

  svg {
    display: block;
    height: 80px;
    margin: 0 auto;
    width: 80px;
  }
}

.headline {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.message {
  margin-bottom: 10px;
  text-align: center;
}

.team-container {
  text-align: center;

  ul {
    margin-bottom: 15px;
  }

  p {
    margin: 0;
  }

  .team-headline {
    font-weight: 500;
  }
}

.md-button {
  padding: 0 20px;

}

.button-center {
  display: block;
  margin: 0 auto 20px auto;
}
</style>
