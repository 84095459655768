<template>
  <div class="transparent-background turnier-detail" :class="showSpeedDial ? 'show': ''">
    <div v-if="turnier != null">
      <header>
        <router-link :to="{name: 'ListTurniere'}">
          <button class="btn-unstyled btn-back">
            <i class="fas fa-chevron-left"></i>
          </button>
        </router-link>
        <h2>{{turnier.turnierName}}</h2>
      </header>
      <div class="image-container"></div>
      <div class="container">
        <transition appear name="fadeRight" mode="out-in">
          <div class="turnier-details-container">
            <TurnierDetailComponent :turnier="turnier"/>
            <div class="button-list">
              <router-link :to="{ name: turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name ? 'ListGruppenSpiele' : 'ListSingleSpiele', params: { turnierID: turnierID }}">
                  <md-button class="md-secondary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextSpiele}}</md-button>
              </router-link>
              <router-link v-if="teamIDByPlayer == 0 && !isTurnierBeendet" :to="{ name: 'ListTeams', params: { turnierID: turnierID }}">
                  <md-button class="md-secondary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextBeitreten}}</md-button>
              </router-link>
              <router-link :to="{ name: 'ListTeams', params: { turnierID: turnierID }}">
                  <md-button class="md-secondary md-round md-raised md-center md-filled">{{lang.detailTurnier.buttonTextTeams}}</md-button>
              </router-link>
            </div>
            <div v-if="!isTurnierBeendet && isOnline" class="button-list button-list-starten">
              <md-button 
                  v-if="isTurnierStatusErstellt && turnier.spielmodus == SPIELMODUS.KO_SYSTEM.name" 
                  @click="activeConfirmDialogCreateSpielplanKO=teamsReady()"
                  class="md-primary md-filled md-round md-raised md-center">
                {{lang.detailTurnier.buttonTextStartenKoPhase}}
              </md-button>
              <md-button 
                  v-if="isTurnierNotStarted && turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name" 
                  @click="activeConfirmDialogCreateTurnierplan=teamsReady()"
                  class="md-primary md-filled md-round md-raised md-center">
                {{lang.detailTurnier.buttonTextStartenGruppenphase}}
              </md-button>
              <md-button 
                  v-if="!isFinalphaseGestartet && turnier.spielmodus == SPIELMODUS.GRUPPEN_SYSTEM.name" 
                  :class="(isGruppenphaseBeendet ? '' : 'disabled')"
                  @click="activeConfirmDialogStartFinalPhase=finalPhaseReady()" 
                  class="md-primary md-filled md-round md-raised md-center">
                {{lang.detailTurnier.buttonTextStartenFinalPhase}}
              </md-button>
            </div>
          </div>
        </transition>
      </div>
      <div class="footer">
        <BottomBarDefault/>
        <md-speed-dial class="md-bottom-center" v-if="isOnline" md-direction="top" md-event="click">
          <md-speed-dial-target class="md-primary md-filled" @click="showSpeedDial = showSpeedDial ? false : true">
            <md-icon class="md-morph-initial">create</md-icon>
            <md-icon class="md-morph-final">close</md-icon>
          </md-speed-dial-target>
          <md-speed-dial-content v-if="showSpeedDial">
            <md-button class="md-icon-button" @click="activeConfirmDialogDelete = true">
              <md-icon>delete_forever</md-icon>
            </md-button>
            <md-button class="md-icon-button" @click="editTurnier(turnier.turnierID)" >
              <md-icon>create</md-icon>
            </md-button>
          </md-speed-dial-content>
        </md-speed-dial>
      </div>
      <md-dialog-confirm
        :md-active.sync="activeConfirmDialogDelete"
        :md-title="lang.detailTurnier.dialogHeadlineDelete" dialogHeadline
        :md-confirm-text="lang.detailTurnier.buttonDialogAgree"
        :md-cancel-text="lang.detailTurnier.buttonDialogCancel"
        @md-confirm="deleteTurnier(turnier)" />
      <md-dialog-confirm
        :md-active.sync="activeConfirmDialogCreateTurnierplan"
        :md-title="lang.detailTurnier.dialogHeadlineCreateGruppenPhase" dialogHeadline
        :md-content="lang.detailTurnier.dialogDescriptionCreateGruppenPhase"
        :md-confirm-text="lang.detailTurnier.buttonDialogAgree"
        :md-cancel-text="lang.detailTurnier.buttonDialogCancel"
        @md-confirm="createGruppenPhase()" />
      <md-dialog-confirm
        :md-active.sync="activeConfirmDialogStartFinalPhase"
        :md-title="lang.detailTurnier.dialogHeadlineStartFinalPhase" dialogHeadline
        :md-content="lang.detailTurnier.dialogDescriptionStartFinalPhase"
        :md-confirm-text="lang.detailTurnier.buttonDialogAgree"
        :md-cancel-text="lang.detailTurnier.buttonDialogCancel"
        @md-confirm="startFinalPhase()" />
      <md-dialog-confirm
        :md-active.sync="activeConfirmDialogCreateSpielplanKO"
        :md-title="lang.detailTurnier.dialogHeadlineKoPhase" dialogHeadline
        :md-content="lang.detailTurnier.dialogDescriptionKoPhase"
        :md-confirm-text="lang.detailTurnier.buttonDialogAgree"
        :md-cancel-text="lang.detailTurnier.buttonDialogCancel"
        @md-confirm="createSpielPlanKo()" />
    </div>
    <SubmitModalFinalPhase :turnierID="turnierID+''" />
    <InformationModal/>
    <SubmitModal/>
  </div>
</template>

<script>
import store from '@/store/index'
import SubmitModal from '../Utils/SubmitModal'
import SubmitModalFinalPhase from '../Utils/SubmitModalFinalPhase'
import InformationModal from '../Utils/InformationModal'
import BottomBarDefault from '../Utils/BottomBarDefault'
import TurnierDetailComponent from './TurnierDetailComponent'
import router from '@/router/index'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'TurnierDetailComponentAdmin',
  props:['turnierID'],
  mixins: [VueOfflineMixin],
  components: {
    SubmitModal,
    SubmitModalFinalPhase,
    InformationModal,
    BottomBarDefault,
    TurnierDetailComponent
  },
  data() {  
    return {
      SPIELMODUS: store.getters.getSpielModus,
      showSpeedDial: false,
      activeConfirmDialogDelete: false,
      activeConfirmDialogCreateTurnierplan: false,
      activeConfirmDialogStartFinalPhase: false,
      activeConfirmDialogCreateSpielplanKO: false,
    }
  },
  methods: {
    async deleteTurnier(turnier) {
      this.activeConfirmDialogDelete = false;
      this.showSpeedDial = false;
      await store.dispatch('deleteTurnier', turnier)
        .then(result => {
          if (result.data != null) {
            this.showDialogActive(true, this.lang.detailTurnier.successHeadlineDelete, "");
            setTimeout(() => { 
              store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false);
              router.push({name: "ListTurniere"}); 
            }, store.state.submitModalSuccessSeconds);
          } else {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
          }
        })
        .catch(error => {
          this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage)
        });
    },
    editTurnier(turnierID) {
      router.push({name: 'EditTurnier', params: {turnierID: turnierID}});
    },
    async createGruppenPhase() {
      await store.dispatch('createTurnierPlanGruppenSystem', this.turnierID)
        .then(result => {
          if (result.data != null) {
            this.showDialogActive(true, this.lang.detailTurnier.successHeadlineTurnierPlanErstellt, "");
            setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
          } else {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
          }
        })
        .catch(error => {
          this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
        });
    },
    async createSpielPlanKo() {
      await store.dispatch('createTurnierPlanKoSystem', this.turnierID)
        .then(result => {
          if (result.data != null) {
            this.showDialogActive(true, this.lang.detailTurnier.successHeadlineTurnierPlanErstellt, "");
            setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
          } else {
            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
          }
        })
        .catch(error => {
          this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
        });
    },
    async startFinalPhase() {
      await store.dispatch('startFinalPhase', {turnierID: this.turnierID, teamsWeiterSamePlatzierung: []})
        .then(result => {
          if (result.data != null) {
            this.showDialogActive(true, this.lang.detailTurnier.successHeadlineStartFinalPhase, "");
            setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
          } else {
            store.commit("SET_SHOW_SUBMIT_MODAL_FINAL_PHASE", {
              show: true,
              headline: this.lang.detailTurnier.modalFinalPhasePlatzierungHeadline,
              message: store.state.turnier.apiReponseErrorMessage,
              data: store.state.turnier.apiReponseErrorData,
            });
          }
        })
        .catch(error => {
          this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.turnier.apiReponseErrorMessage);
        });
    },
    teamsReady() {
      const teamsJoined = store.getters.getTeamsLength;
      if (teamsJoined == this.turnier.anzTeams) {
        return true;
      }
      else {
        const missingTeams = this.turnier.anzTeams-teamsJoined;
          store.commit("SET_SHOW_INFORMATION_DIALOG", {
            show: true,
            headline: this.lang.detailTurnier.modalTeamsMissingHeadline,
            message: this.lang.detailTurnier.modalTeamsMissingMessage1 + missingTeams + (missingTeams == 1 ? ' Team ': ' Teams ') + this.lang.detailTurnier.modalTeamsMissingMessage2
          });
        
        return false;
      }
    },
    finalPhaseReady() {
      if (this.isGruppenphaseBeendet) {
          return true;
        } else {
          store.commit("SET_SHOW_INFORMATION_DIALOG", {
            show: true,
            headline: this.lang.detailTurnier.modalTeamsMissingHeadline,
            message: this.lang.detailTurnier.modalFinalPhase
          });
          return false;
      }
    },
    showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
      store.commit("SET_SHOW_SUBMIT_MODAL", {
        show: true,
        success: formSuccess,
        headline: formSuccessHeadline,
        message: formSuccessMessage
      });
    },
  },
  computed: {
    lang() {
      return store.getters.getLanguage;
    },
    spielerID() {
      return store.getters.getSpielerID;
    },
    teamIDByPlayer() {
      return store.getters.getPlayerTeamNumber(this.spielerID);
    },
    turnier() {
      return store.getters.getTurnier;
    },
    isTurnierBeendet() {
      return store.getters.isTurnierBeendet;
    },
    isGruppenphaseBeendet() {
      return store.getters.isGruppenphaseBeendet;
    },
    isTurnierNotStarted() {
      return store.getters.isTurnierStatusErstellt || store.getters.isTurnierStatusTurnierPlanErstellt;
    },
    isTurnierStatusErstellt() {
      return store.getters.isTurnierStatusErstellt;
    },
    isFinalphaseGestartet() {
      return store.getters.isFinalphaseGestartet;
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/base';
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 6;
}

.md-speed-dial {
  bottom: 35px;
  z-index: 10;
}
</style>
